<template>
  <div v-bind="$attrs" class="vendor-chat">
    <div class="loading width-expand flex-middle flex-center"  v-if="!initialFetchComplete" style="height: 300px;">
      <spinner :size="3"></spinner>
    </div>

    <div v-if="!finalConversationUuid && initialFetchComplete" class="start-chat-ui">
      <h4>
        {{translateUcFirst('chat.vendor.title', {vendorName})}}
      </h4>
      <div class="start-ui">
        <div class="flex gap-m flex-middle flex-wrap width-expand padding-bottom">
          <form-input
            v-model="initialMessageText"
            class="message-input width-expand"
            :label="false"
            placeholder="chat.message.sendUi.contentPlaceholder"
            style="min-height: 0px"
            type="textarea"
            @keyup.enter="startVendorChat()"></form-input>

          <form-button
            class="message-send-cta"
            icon-end="chevron-inline-end"
            text="chat.message.sendUi.sendCta"
            @click="startVendorChat()"></form-button>
        </div>
      </div>
    </div>
    <chat
      v-if="finalConversationUuid && initialFetchComplete"
      :conversation-uuid="finalConversationUuid"
      class="width-expand"></chat>
  </div>
</template>

<script>
import useChat from "@/client/extensions/composition/useChat";
import asyncOperations from "@/client/extensions/composition/asyncOperations";
import Chat from "@/client/components/chat/Chat.vue";

export default {
  components: {
    Chat,
  },
  props: {
    vendorId: {
      type: Number,
      default: false, // this is intentional: must provide number ID
    },
    vendorName: {
      type: [String, Boolean],
      default: false,
    },
    conversationUuid: {
      type: [String, Boolean],
      default: false,
    },
  },
  emits: ["chat-started"],
  setup(props) {
    let { asyncOps, asyncOpsReady, asyncStatus } = asyncOperations(props);
    let { chat } = useChat();
    return { asyncOps, asyncOpsReady, asyncStatus, chat };
  },
  data: function () {
    return {
      initialFetchComplete: false,
      initialMessageText: "",
      localConversationUuid: false,
      expanded: true,
      isFullWidth: false,
    };
  },
  computed: {
    finalConversationUuid() {
      if (this.conversationUuid && this.conversationUuid != '') {
        return this.conversationUuid;
      }

      return this.localConversationUuid;
    },
    chatCtaTitle() {
      if (this.vendorName) {
        return this.translateUcFirst("ecommerce.vendor.chat.ctaTitleGeneric");
      } else {
        return this.translateUcFirst("ecommerce.vendor.chat.ctaTitleSpecific", {
          vendorName: this.vendorName,
        });
      }
    },
  },
  watch: {},
  methods: {
    async startVendorChat() {
      let res = await this.chat.messageVendor(this.initialMessageText, this.vendorId);

      if (res.hasError) {
        this.$s.ui.notification("core.errorGeneric", "error");
        return;
      }

      this.localConversationUuid = res.data.conversationUuid;
      this.$emit("chat-started", {
        conversationUuid: this.conversationUuid,
        text: this.initialMessageText,
      });

      this.initialMessageText = "";
      return true;
    },
  },
  created() {
    this.chat.getVendorUserConversationUuid(this.vendorId).then(res => {
      if (res.data.conversationUuid) {
        this.localConversationUuid = res.data.conversationUuid;
      }

      this.initialFetchComplete = true;
    });
  }
};
</script>

<style scoped lang="scss"></style>